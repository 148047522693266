@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import 'react-phone-number-input/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  /* border: 1px solid red; */
}

.test * {

  border: 1px solid red;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #242424;
}

.carouselBg {
  background: url('https://b2256232.smushcdn.com/2256232/wp-content/uploads/2020/05/h1-img-16.png?lossy=0&strip=1&webp=1');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}



.numinput input {
  border: 1px solid #CECECE;
  width: 500px;
  outline: none;
  padding: 16px 14px;
  border-radius: 6px;
  margin-top: 6px;
}